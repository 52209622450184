import { Classes } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import React, { ReactNode, useCallback, useMemo } from "react";
import styled from "styled-components";

import { HexButton, HexMenuItem, HexSpinner } from "../../hex-components";
import {
  BigMenuItem,
  BigMenuItemTextRenderer,
} from "../../hex-components/HexMenuItem";
import { SingleChevronDownIcon } from "../icons/CustomIcons";

const SelectedItem = styled.span`
  display: inline-flex;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const InlineRightItem = styled.div`
  display: inline-block;
  margin-left: 6px;
`;

export const BasePickerButton = styled(HexButton)`
  white-space: nowrap;
  .${Classes.BUTTON_TEXT}:not(:last-child) {
    margin-right: 3px;
  }

  &.${Classes.DISABLED} {
    .${Classes.BUTTON_TEXT} span,
    .${Classes.ICON} {
      color: ${({ theme }) => theme.fontColor.MUTED};

      opacity: 0.5;
    }
  }
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const PickerButton = styled(BasePickerButton).attrs(() => ({
  rightIcon: <SingleChevronDownIcon iconSize={14} />,
}))``;

export interface PickerItem<T extends string> {
  key: T;
  title: ReactNode;
  description?: ReactNode;
  rightElement?: ReactNode;
  disabled?: boolean;
  selectedItemTextColor?: string;
}

interface PickerProps<T extends string> {
  selectedItem: T | null;
  items: Array<PickerItem<T>>;
  onSelect: (item: T) => void;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  captureDismiss?: boolean;
  loading?: boolean;
  dataCy?: string;
  className?: string;
  bigItems?: boolean; // defaults to true
  minimal?: boolean; // defaults to true
  smallPicker?: boolean; // defaults to true
}

export function Picker<T extends string>({
  bigItems = true,
  captureDismiss = false,
  className,
  dataCy,
  disabled,
  error,
  items,
  label,
  loading,
  minimal = true,
  onSelect,
  selectedItem,
  smallPicker = true,
}: PickerProps<T>): JSX.Element | null {
  const activeItem = items.find((i) => i.key === selectedItem);

  const ItemComponent = bigItems ? BigMenuItem : HexMenuItem;
  const itemRenderer: ItemRenderer<PickerItem<T>> = useCallback(
    (item: PickerItem<T>, { handleClick, modifiers: { active } }) => {
      return (
        <ItemComponent
          key={item.key}
          $align="start"
          active={active}
          disabled={item.disabled}
          multiline={true}
          text={
            <BigMenuItemTextRenderer
              active={active}
              description={item.description}
              disabled={item.disabled}
              name={item.title}
              rightElement={item.rightElement}
            />
          }
          onClick={handleClick}
        />
      );
    },
    [ItemComponent],
  );

  const onItemSelect = useCallback(
    (item: PickerItem<T>) => onSelect(item.key),
    [onSelect],
  );

  const noResultText = useMemo(() => {
    if (error) {
      return "Error loading options.";
    } else if (loading) {
      return <HexSpinner />;
    } else {
      return "No results.";
    }
  }, [error, loading]);

  return (
    <Select<PickerItem<T>>
      activeItem={activeItem}
      disabled={disabled}
      filterable={false}
      itemRenderer={itemRenderer}
      items={items}
      noResults={<HexMenuItem disabled={true} text={noResultText} />}
      popoverProps={{
        minimal: true,
        placement: "bottom-start",
        position: undefined,
        rootBoundary: "viewport",
        captureDismiss,
      }}
      onItemSelect={onItemSelect}
    >
      <PickerButton
        className={className}
        data-cy={dataCy}
        disabled={disabled}
        minimal={minimal}
        small={smallPicker}
        text={
          <>
            {label ? `${label}: ` : ""}
            {activeItem ? (
              <SelectedItem>
                <div style={{ color: activeItem.selectedItemTextColor }}>
                  {activeItem.title}
                </div>
                {activeItem?.rightElement && (
                  <InlineRightItem>{activeItem.rightElement}</InlineRightItem>
                )}
              </SelectedItem>
            ) : (
              "Select..."
            )}
          </>
        }
      />
    </Select>
  );
}
